<template>
	<div>
		<el-form-item label="轮播" prop="carousel" label-width="40px">
			<div v-for="(carousel, c) in special.form.carousel" :key="carousel.key">
				<el-form-item :label="'名称'+(c+1)" :prop="'form.carousel.' + c + '.title'" :rules="{ required: true, message: '请输入名称'+(c+1), trigger: 'blur' }">
					<el-input v-model="carousel.title" placeholder="请输入名称。" maxlength="18" show-word-limit clearable>
						<el-popconfirm slot="append" title="确定删除这条记录吗？" v-if="special.form.carousel.length > 1" @confirm="removeItem('guide', item)">
							<el-button slot="reference" icon="el-icon-delete"></el-button>
						</el-popconfirm>
					</el-input>
				</el-form-item>
				<el-form-item :label="'链接'+(c+1)" :prop="'form.carousel.' + c + '.url'" :rules="{ required: true, message: '请输入链接'+(c+1), trigger: 'blur' }">
					<el-input v-model="carousel.url" placeholder="链接" clearable/>
				</el-form-item>
				<el-form-item :label="'图片'+(c+1)" :prop="'form.carousel.' + c + '.thumb'" :rules="{ required: true, message: '请输入图片'+(c+1), trigger: 'blur' }">
					<el-input v-model="carousel.thumb" placeholder="图片" clearable>
						<el-button slot="prepend" icon="el-icon-upload"></el-button>
						<el-button slot="append" icon="el-icon-view" @click="$utils.openWindow(carousel.thumb)"></el-button>
					</el-input>
				</el-form-item>
			</div>
		</el-form-item>
		<el-form-item label="专题" prop="special" label-width="40px">
			<el-form-item label="导航">
				<div v-for="(menu, m) in special.form.special.menus" :key="menu.key">
					<el-form-item :label="'名称'+(m+1)" :prop="'form.special.menus.' + m + '.name'" :rules="{ required: true, message: '请输入名称'+(m+1), trigger: 'blur' }">
						<el-input v-model="menu.name" placeholder="请输入名称。" maxlength="18" show-word-limit clearable>
							<el-popconfirm slot="append" title="确定删除这条记录吗？" v-if="special.form.special.menus.length > 1" @confirm="removeItem('guide', item)">
								<el-button slot="reference" icon="el-icon-delete"></el-button>
							</el-popconfirm>
						</el-input>
					</el-form-item>
					<el-form-item :label="'链接'+(m+1)" :prop="'form.special.menus.' + m + '.url'" :rules="{ required: true, message: '请输入链接'+(m+1), trigger: 'blur' }">
						<el-input v-model="menu.url" placeholder="链接" clearable/>
					</el-form-item>
				</div>
			</el-form-item>
		</el-form-item>
		<el-form-item label="关注" prop="follow" label-width="40px">
			<el-form-item label="导航">
				<div v-for="(menu, m) in special.form.follow.menus" :key="menu.key">
					<el-form-item :label="'名称'+(m+1)" :prop="'form.follow.menus.' + m + '.name'" :rules="{ required: true, message: '请输入名称'+(m+1), trigger: 'blur' }">
						<el-input v-model="menu.name" placeholder="请输入名称。" maxlength="18" show-word-limit clearable>
							<el-popconfirm slot="append" title="确定删除这条记录吗？" v-if="special.form.follow.menus.length > 1" @confirm="removeItem('guide', item)">
								<el-button slot="reference" icon="el-icon-delete"></el-button>
							</el-popconfirm>
						</el-input>
					</el-form-item>
					<el-form-item :label="'链接'+(m+1)" :prop="'form.follow.menus.' + m + '.url'" :rules="{ required: true, message: '请输入链接'+(m+1), trigger: 'blur' }">
						<el-input v-model="menu.url" placeholder="链接" clearable/>
					</el-form-item>
				</div>
			</el-form-item>
		</el-form-item>
		<el-form-item label="楼层" prop="floor" label-width="40px">
			<el-card>
				<div v-for="(item, index) in special.form.floor" :key="item.key">
					<el-form-item :label="'类目ID'+(index+1)" :prop="'form.floor.' + index + '.category_id'" :rules="[{ required: true, message: '请输入类目ID'+(index+1), trigger: 'blur' }, { type: 'number', message: '请填数字', transform: (v) => Number(v)}]">
						<el-input  v-model="item.category_id" placeholder="请输入类目ID。" >
							<el-popconfirm slot="append" title="确定删除这条记录吗？" v-if="special.form.floor.length > 1" @confirm="removeItem('floor', item)">
								<el-button slot="reference" icon="el-icon-delete"></el-button>
							</el-popconfirm>
						</el-input>
					</el-form-item>
					<el-form-item :label="'图标'+(index+1)" :prop="'form.floor.' + index + '.icon'" :rules="{ required: true, message: '请输入图标'+(index+1), trigger: 'blur' }">
						<el-input v-model="item.icon" placeholder="图标" clearable>
						<el-button slot="prepend" icon="el-icon-upload"></el-button>
						<el-button slot="append" icon="el-icon-view" @click="$utils.openWindow(item.icon)"></el-button>
						</el-input>
					</el-form-item>
					<el-form-item :label="'宫格'+(index+1)" :prop="'form.floor.' + index + '.grid'" :rules="{ required: true, message: '请输入宫格'+(index+1), trigger: 'blur' }">
						<el-input v-model="item.grid" placeholder="图标" onchange="value=value.replace(/，/g, ',')" clearable/>
					</el-form-item>
					<el-form-item label="导航">
						<div v-for="(menu, m) in item.menus" :key="menu.key" :style="'background-repeat: no-repeat; background-position: left bottom; background-size: 32px;background-image: url('+menu.icon+')'">
							<el-form-item :label="'名称'+(m+1)" :prop="'form.guide.' + index + '.name'" :rules="{ required: true, message: '请输入名称'+(m+1), trigger: 'blur' }">
								<el-input v-model="menu.name" placeholder="请输入名称。" maxlength="18" show-word-limit clearable>
									<el-popconfirm slot="append" title="确定删除这条记录吗？" v-if="special.form.guide.length > 1" @confirm="removeItem('guide', item)">
										<el-button slot="reference" icon="el-icon-delete"></el-button>
									</el-popconfirm>
								</el-input>
							</el-form-item>
							<el-form-item :label="'链接'+(m+1)" :prop="'form.guide.' + index + '.url'" :rules="{ required: true, message: '请输入链接'+(m+1), trigger: 'blur' }">
								<el-input v-model="menu.url" placeholder="链接" clearable/>
							</el-form-item>
						</div>
					</el-form-item>
				</div>
			</el-card>
		</el-form-item>
		<el-form-item label="常用" prop="form.common" label-width="40px">
			<el-card>
				<div v-for="(item, index) in special.form.common" :key="item.key">
					<el-form-item :label="'名称'+(index+1)" :prop="'form.common.' + index + '.name'" :rules="{ required: true, message: '请输入名称'+(index+1), trigger: 'blur' }">
						<el-input  v-model="item.name" placeholder="请输入名称。" maxlength="18" show-word-limit clearable>
							<el-popconfirm slot="append" title="确定删除这条记录吗？" v-if="special.form.common.length > 1" @confirm="removeItem('common', item)">
								<el-button slot="reference" icon="el-icon-delete"></el-button>
							</el-popconfirm>
						</el-input>
					</el-form-item>
					<el-form-item label="菜单">
						<div v-for="(menu, m) in item.menus" :key="menu.key">
							<el-form-item :label="'名称'+(m+1)" :prop="'form.guide.' + index + '.name'" :rules="{ required: true, message: '请输入名称'+(m+1), trigger: 'blur' }">
								<el-input v-model="menu.name" placeholder="请输入名称。" maxlength="18" show-word-limit clearable>
									<el-popconfirm slot="append" title="确定删除这条记录吗？" v-if="special.form.guide.length > 1" @confirm="removeItem('guide', item)">
										<el-button slot="reference" icon="el-icon-delete"></el-button>
									</el-popconfirm>
								</el-input>
							</el-form-item>
							<el-form-item label="链接" :prop="'form.guide.' + index + '.url'" :rules="{ required: true, message: '请输入链接'+(m+1), trigger: 'blur' }">
								<el-input v-model="menu.url" placeholder="链接" clearable/>
							</el-form-item>
							<el-form-item label="图标" :prop="'form.guide.' + index + '.icon'" :rules="{ required: true, message: '请输入图标'+(m+1), trigger: 'blur' }">
								<el-input v-model="menu.icon" placeholder="图标" clearable>
									<el-button slot="prepend" icon="el-icon-upload"></el-button>
									<el-button slot="append" icon="el-icon-view" @click="$utils.openWindow(menu.icon)"></el-button>
								</el-input>
							</el-form-item>
						</div>
						<el-button type="primary" @click="addItem('guide')" v-if="special.form.guide.length < 10">可添加 {{10-special.form.guide.length}}</el-button>
					</el-form-item>
				</div>
				<el-button type="primary" @click="addItem('common')" v-if="special.form.common.length < 10">可添加 {{10-special.form.common.length}}</el-button>
			</el-card>
		</el-form-item>
		<el-form-item label="便民" prop="form.guide" label-width="40px">
			<el-card>
				<div v-for="(item, index) in special.form.guide" :key="item.key">
					<el-form-item :label="'名称'+(index+1)" :prop="'form.guide.' + index + '.name'" :rules="{ required: true, message: '请输入名称'+(index+1), trigger: 'blur' }">
						<el-input  v-model="item.name" placeholder="请输入名称。" maxlength="18" show-word-limit clearable>
							<el-popconfirm slot="append" title="确定删除这条记录吗？" v-if="special.form.guide.length > 1" @confirm="removeItem('guide', item)">
								<el-button slot="reference" icon="el-icon-delete"></el-button>
							</el-popconfirm>
						</el-input>
					</el-form-item>
					<el-form-item label="链接" :prop="'form.guide.' + index + '.url'" :rules="{ required: true, message: '请输入链接'+(index+1), trigger: 'blur' }">
						<el-input v-model="item.url" placeholder="链接" clearable/>
					</el-form-item>
					<el-form-item label="图标" :prop="'form.guide.' + index + '.icon'" :rules="{ required: true, message: '请输入图标'+(index+1), trigger: 'blur' }">
						<el-input v-model="item.icon" placeholder="图标" clearable>
							<el-button slot="prepend" icon="el-icon-upload"></el-button>
							<el-button slot="append" icon="el-icon-view" @click="$utils.openWindow(item.icon)"></el-button>
						</el-input>
					</el-form-item>
				</div>
				<el-button type="primary" @click="addItem('guide')" v-if="special.form.guide.length < 10">可添加 {{10-special.form.guide.length}}</el-button>
			</el-card>
		</el-form-item>
	</div>
</template>

<script>
	export default {
		name: 'Www',
		computed: {
		},
		props: {
			special: {
				type: Object
			}
		},
		methods: {
			removeItem (f, i) {
				var index = this.special.form[f].indexOf(i)
				if (index !== -1) {
					this.special.form[f].splice(index, 1)
				}
			},
			addItem (f) {
				this.special.form[f].push(JSON.parse(JSON.stringify(this.form[f][0])));
			}
		},
		mounted () {
			if (this.special.id) return false;
			this.special.form = JSON.parse(JSON.stringify(this.form));
		},
		data() {
			return {
				form: {
					carousel: [
						{
							title: '凉快圣诞节快乐房管局圣诞快乐',
							url: '/',
							thumb: 'https://img1.citys114.com/szcitys114/20210826/20210826023001_45070.jpg'
						}, {
							title: '凉快圣诞节快乐房管局圣诞快乐',
							url: '/',
							thumb: 'https://img1.citys114.com/szcitys114/20210826/20210826023001_45070.jpg'
						}, {
							title: '凉快圣诞节快乐房管局圣诞快乐',
							url: '/',
							thumb: 'https://img1.citys114.com/szcitys114/20210826/20210826023001_45070.jpg'
						}
					],
					special: {
						menus: [
							{
								name: 'HPV疫苗',
								url: '/'
							}, {
								name: '毕业生入户',
								url: '/'
							}
						],
						id: "4810,13423"
					},
					follow: {
						menus: [
							{
								name: '今日热点',
								url: '/'
							}, {
								name: '限行',
								url: '/'
							}
						],
						id: "13361,13423,13485,13457,13468"
					},
					floor: [
						{
							category_id: 2,
							icon: '/',
							grid: "4810",
							menus: [
								{
									name: '新冠疫苗预约',
									url: '/'
								}, {
									name: '核酸检测',
									url: '/'
								}
							]
						}, {
							category_id: 4,
							icon: 'https://sz.citys114.com/static/images/index/ico_banshi.png',
							grid: "5464",
							menus: [
								{
									name: '基层就业补贴',
									url: '/'
								}, {
									name: '香港通关',
									url: '/'
								}
							]
						}, {
							category_id: 6,
							icon: 'https://sz.citys114.com/static/images/index/ico_jiaotong.png',
							grid: "4213",
							menus: [
								{
									name: '赣深高铁',
									url: '/'
								}, {
									name: '深大城际',
									url: '/'
								}
							]
						}, {
							category_id: 5,
							icon: 'https://sz.citys114.com/static/images/index/ico_edu.png',
							grid: "12922",
							menus: [
								{
									name: '小一初一报名',
									url: '/'
								}, {
									name: '幼儿园报名',
									url: '/'
								}
							]
						}, {
							category_id: 3,
							icon: 'https://sz.citys114.com/static/images/index/ico_meishi.png',
							grid: "11562,12916,12915,12914",
							menus: [
								{
									name: '端午活动汇总',
									url: '/'
								}, {
									name: '漫展',
									url: '/'
								}
							]
						}, {
							category_id: 8,
							icon: 'https://sz.citys114.com/static/images/index/ico_lvyou.png',
							grid: "12216,12916,12915,12914",
							menus: [
								{
									name: '一日游',
									url: '/'
								}, {
									name: '亲子游',
									url: '/'
								}
							]
						}, 
					],
					common: [
						{
							name: '常用查询',
							menus: [
								{
									name: '公积金查询',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-1.png'
								}, {
									name: '学区查询',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-2.png'
								}, {
									name: '社保查询',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-3.png'
								}, {
									name: '学区锁定查询',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-1.png'
								}, {
									name: '违章查询',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-2.png'
								}, {
									name: '地铁查询',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-2.png'
								}
							]
						}, {
							name: '热门预约',
							menus: [
								{
									name: '免限行预约',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-1.png'
								}, {
									name: '东部预约',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-2.png'
								}, {
									name: '预约挂号',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-3.png'
								}, {
									name: '景点预约',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-1.png'
								}, {
									name: '出入境预约',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-2.png'
								}
							]
						}, {
							name: '网上办事',
							menus: [
								{
									name: '公租房申请',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-1.png'
								}, {
									name: '积分入户',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-2.png'
								}, {
									name: '车牌摇号',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-3.png'
								}, {
									name: '社保证明',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-1.png'
								}
							]
						}, {
							name: '实用工具',
							menus: [
								{
									name: '房贷计算器',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-1.png'
								}, {
									name: '个税计算器',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-2.png'
								}, {
									name: '养老金计算器',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-3.png'
								}, {
									name: '个人征信查询',
									url: '/',
									icon: 'https://sz.citys114.com/static/images/index/i-1.png'
								}
							]
						}
					],
					guide: [
						{
							name: '租房补贴',
							url: '/',
							icon: 'https://sz.citys114.com/static/images/bianmin/zhufang.png'
						}, {
							name: 'HPV疫苗接种',
							url: '/',
							icon: 'https://sz.citys114.com/static/images/bianmin/guahao.png'
						}, {
							name: '核酸检测',
							url: '/',
							icon: 'https://sz.citys114.com/static/images/bianmin/guahao.png'
						}, {
							name: '港澳通行证',
							url: '/',
							icon: 'https://sz.citys114.com/static/images/bianmin/weizhang.png'
						}, {
							name: '居住证',
							url: '/',
							icon: 'https://sz.citys114.com/static/images/bianmin/weizhang.png'
						}, {
							name: '人才引进',
							url: '/',
							icon: 'https://sz.citys114.com/static/images/bianmin/crj.png'
						}, {
							name: '房屋租赁凭证',
							url: '/',
							icon: 'https://sz.citys114.com/static/images/bianmin/ico_gzf_00.png'
						}, {
							name: '犬只芯片',
							url: '/',
							icon: 'https://sz.citys114.com/static/images/bianmin/weizhang.png'
						}, {
							name: '专属医疗险',
							url: '/',
							icon: 'https://sz.citys114.com/static/images/bianmin/guahao.png'
						}
					]
				}
			}
		}
	}
</script>

<style>

</style>