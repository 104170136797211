<template>
	<div>
		<el-form-item label="轮播" prop="banners" label-width="40px">
			<div v-for="(banner, c) in special.form.banners" :key="banner.key">
				<el-form-item label-width="60px" :label="'名称'+(c+1)" :prop="'form.banners.' + c + '.title'" :rules="{ required: true, message: '请输入名称'+(c+1), trigger: 'blur' }">
					<el-input v-model="banner.title" placeholder="请输入名称。" maxlength="18" show-word-limit clearable>
						<el-popconfirm slot="append" title="确定删除这条记录吗？" v-if="special.form.banners.length > 1" @confirm="removeItem('banners', banner)">
							<el-button slot="reference" icon="el-icon-delete"></el-button>
						</el-popconfirm>
					</el-input>
				</el-form-item>
				<el-form-item label-width="60px" :label="'链接'+(c+1)" :prop="'form.banners.' + c + '.url'" :rules="{ required: true, message: '请输入链接'+(c+1), trigger: 'blur' }">
					<el-input v-model="banner.url" placeholder="链接" clearable>
						<el-select v-model="banner.target" slot="prepend" style="width: 100px" placeholder="请选择">
							<el-option label="原标签" value="_self"></el-option>
							<el-option label="新标签" value="_blank"></el-option>
						</el-select>
					</el-input>
				</el-form-item>
				<el-form-item label-width="60px" :label="'图片'+(c+1)" :prop="'form.banners.' + c + '.thumb'" :rules="{ required: true, message: '请输入图片'+(c+1), trigger: 'blur' }">
					<el-input v-model="banner.thumb" placeholder="图片" clearable>
						<el-upload
							slot="prepend"
							:show-file-list="false"
							:data="{type: 'article_thumb'}"
							:on-success="(r, f, l) => { !r.code ? (banner.thumb = r.result.url) : $message.error(r.msg)}"
							:action="$config.APIHOST+'uploads'">
							<el-button icon="el-icon-upload"></el-button>
						</el-upload>
						<el-button slot="append" icon="el-icon-view" @click="$utils.openWindow(banner.thumb)"></el-button>
					</el-input>
				</el-form-item>
			</div>
			<el-button type="primary" @click="addItem('banners')" v-if="special.form.banners.length < 9">可添加 {{9-special.form.banners.length}}</el-button>
		</el-form-item>
		<el-form-item label="卡片" prop="cards" label-width="40px">
			<div v-for="(card, m) in special.form.cards" :key="card.key">
				<el-form-item label-width="60px" :label="'名称'+(m+1)" :prop="'form.cards.' + m + '.title'" :rules="{ required: true, message: '请输入名称'+(m+1), trigger: 'blur' }">
					<el-input v-model="card.title" placeholder="请输入名称。" maxlength="18" show-word-limit clearable>
						<el-popconfirm slot="append" title="确定删除这条记录吗？" v-if="special.form.cards.length > 1" @confirm="removeItem('cards', card)">
							<el-button slot="reference" icon="el-icon-delete"></el-button>
						</el-popconfirm>
					</el-input>
				</el-form-item>
				<el-form-item label-width="60px" :label="'链接'+(m+1)" :prop="'form.cards.' + m + '.url'" :rules="{ required: true, message: '请输入链接'+(m+1), trigger: 'blur' }">
					<el-input v-model="card.url" placeholder="链接" clearable>
						<el-select v-model="card.target" slot="prepend" style="width: 100px" placeholder="请选择">
							<el-option label="原标签" value="_self"></el-option>
							<el-option label="新标签" value="_blank"></el-option>
						</el-select>
					</el-input>
				</el-form-item>
				<el-form-item label-width="60px" :label="'图片'+(m+1)" :prop="'form.cards.' + m + '.thumb'" :rules="{ required: true, message: '请输入图片'+(m+1), trigger: 'blur' }">
					<el-input v-model="card.thumb" placeholder="图片" clearable>
						<el-upload
							slot="prepend"
							:show-file-list="false"
							:data="{type: 'article_thumb'}"
							:on-success="(r, f, l) => { !r.code ? (card.thumb = r.result.url) : $message.error(r.msg)}"
							:action="$config.APIHOST+'uploads'">
							<el-button icon="el-icon-upload"></el-button>
						</el-upload>
						<el-button slot="append" icon="el-icon-view" @click="$utils.openWindow(card.thumb)"></el-button>
					</el-input>
				</el-form-item>
			</div>
			<el-button type="primary" @click="addItem('cards')" v-if="special.form.cards.length < 3">可添加 {{3-special.form.cards.length}}</el-button>
		</el-form-item>
		<el-form-item label="品牌" prop="brands" label-width="40px">
			<div v-for="(brand, index) in special.form.brands" :key="brand.key">
				<el-form-item label-width="60px" :label="'名称'+(index+1)" :prop="'form.brands.' + index + '.title'" :rules="{ required: true, message: '请输入名称'+(index+1), trigger: 'blur' }">
					<el-input v-model="brand.title" placeholder="请输入名称。" maxlength="18" show-word-limit clearable>
						<el-popconfirm slot="append" title="确定删除这条记录吗？" v-if="special.form.brands.length > 1" @confirm="removeItem('brands', brand)">
							<el-button slot="reference" icon="el-icon-delete"></el-button>
						</el-popconfirm>
					</el-input>
				</el-form-item>
				<el-form-item label-width="60px" label="链接" :prop="'form.brands.' + index + '.url'" :rules="{ required: true, message: '请输入链接'+(index+1), trigger: 'blur' }">
					<el-input v-model="brand.url" placeholder="链接" clearable>
						<el-select v-model="brand.target" slot="prepend" style="width: 100px" placeholder="请选择">
							<el-option label="原标签" value="_self"></el-option>
							<el-option label="新标签" value="_blank"></el-option>
						</el-select>
					</el-input>
				</el-form-item>
				<el-form-item label-width="60px" label="图标" :prop="'form.brands.' + index + '.icon'" :rules="{ required: true, message: '请输入图标'+(index+1), trigger: 'blur' }">
					<el-input v-model="brand.icon" placeholder="图标" clearable>
						<el-upload
							slot="prepend"
							:show-file-list="false"
							:data="{type: 'article_thumb'}"
							:on-success="(r, f, l) => { !r.code ? (brand.thumb = r.result.url) : $message.error(r.msg)}"
							:action="$config.APIHOST+'uploads'">
							<el-button icon="el-icon-upload"></el-button>
						</el-upload>
						<el-button slot="append" icon="el-icon-view" @click="$utils.openWindow(brand.icon)"></el-button>
					</el-input>
				</el-form-item>
			</div>
			<el-button type="primary" @click="addItem('brands')" v-if="special.form.brands.length < 9">可添加 {{9-special.form.brands.length}}</el-button>
		</el-form-item>
		<el-form-item label="重要公告" prop="form.notice_category_id" :rules="{ required: true, message: '请输入类目ID。', trigger: 'blur' }" label-width="100px">
			<el-input v-model="special.form.notice_category_id"  placeholder="请输入类目ID。" clearable/>
		</el-form-item>
		<el-form-item label="帮助中心" prop="form.help_category_id" :rules="{ required: true, message: '请输入类目ID。', trigger: 'blur' }" label-width="100px">
			<el-input v-model="special.form.help_category_id" placeholder="请输入类目ID。" clearable/>
		</el-form-item>
		<el-form-item label="发现“性”趣" prop="form.sex_category_id" :rules="{ required: true, message: '请输入类目ID。', trigger: 'blur' }" label-width="100px">
			<el-input v-model="special.form.sex_category_id" placeholder="请输入类目ID。" clearable/>
		</el-form-item>
	</div>
</template>

<script>
	export default {
		name: 'Www',
		computed: {
		},
		props: {
			special: {
				type: Object
			}
		},
		methods: {
			onLog(r, f, l) {
				console.log(r, f, l)
			},
			removeItem (f, i) {
				var index = this.special.form[f].indexOf(i)
				if (index !== -1) {
					this.special.form[f].splice(index, 1)
				}
			},
			addItem (f) {
				this.special.form[f].push(JSON.parse(JSON.stringify(this.form[f][0])));
			}
		},
		mounted () {
			if (this.special.id) return false;
			this.special.form = JSON.parse(JSON.stringify(this.form));
		},
		data() {
			return {
				form: {
					banners: [
						{
							title: '凉快圣诞节快乐房管局圣诞快乐',
							target: '_blank',
							url: '/',
							thumb: 'https://imgv1.citys114.com/szcitys114/20210826/20210826023001_45070.jpg'
						}, {
							title: '凉快圣诞节快乐房管局圣诞快乐',
							target: '_blank',
							url: '/',
							thumb: 'https://imgv1.citys114.com/szcitys114/20210826/20210826023001_45070.jpg'
						}, {
							title: '凉快圣诞节快乐房管局圣诞快乐',
							target: '_blank',
							url: '/',
							thumb: 'https://imgv1.citys114.com/szcitys114/20210826/20210826023001_45070.jpg'
						}
					],
					cards: [
						{
							title: '今日热点',
							target: '_blank',
							url: '/',
							thumb: 'https://imgv1.citys114.com/szcitys114/20210826/20210826023001_45070.jpg'
						}, {
							title: '限行',
							target: '_blank',
							url: '/',
							thumb: 'https://imgv1.citys114.com/szcitys114/20210826/20210826023001_45070.jpg'
						}, {
							title: '限行',
							target: '_blank',
							url: '/',
							thumb: 'https://imgv1.citys114.com/szcitys114/20210826/20210826023001_45070.jpg'
						}
					],
					notice_category_id: 1,
					help_category_id: 1,
					sex_category_id: 1,
					ids: '80',
					brands: [
						{
							title: '杜蕾斯',
							target: '_blank',
							url: '/',
							icon: 'https://s.cccgx.com/data/brandlogo/1332236988948109070.jpg'
						}, {
							title: '积之美',
							target: '_blank',
							url: '/',
							icon: 'https://s.cccgx.com/data/brandlogo/1332236945812531445.jpg'
						}, {
							title: 'FunFactory',
							target: '_blank',
							url: '/',
							icon: 'https://s.cccgx.com/data/brandlogo/1332237491680056820.jpg'
						}, {
							title: '诺兰',
							target: '_blank',
							url: '/',
							icon: 'https://s.cccgx.com/data/brandlogo/1442807201576592261.jpg'
						}, {
							title: 'LELO',
							target: '_blank',
							url: '/',
							icon: 'https://s.cccgx.com/data/brandlogo/1332237178310748384.jpg'
						}, {
							title: '冈本',
							target: '_blank',
							url: '/',
							icon: 'https://s.cccgx.com/data/brandlogo/1332243221637448132.jpg'
						}, {
							title: '百乐',
							target: '_blank',
							url: '/',
							icon: 'https://s.cccgx.com/data/brandlogo/1332244813781998166.jpg'
						}, {
							title: 'Key',
							target: '_blank',
							url: '/',
							icon: 'https://s.cccgx.com/data/brandlogo/1442807505282389251.jpg'
						}, {
							title: '瑟蓓绮',
							target: '_blank',
							url: '/',
							icon: 'https://s.cccgx.com/data/brandlogo/1442807488969433993.jpg'
						}
					]
				}
			}
		}
	}
</script>

<style>

</style>